<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-white">
    <Popover class="relative bg-pb shadow-md">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div
          class="flex justify-between items-center py-6 md:justify-start md:space-x-10"
        >
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link to="/">
              <img class="h-12 w-auto sm:h-10" src="@/assets/5.png" alt="" />
            </router-link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton
              class="bg-pb p-2 inline-flex items-center justify-center text-sg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sg"
            >
              <span class="sr-only">Open menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex space-x-10">
            <router-link
              to="/"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Home
            </router-link>
            <router-link
              to="/menu"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Menu
            </router-link>
            <router-link
              to="/brunch"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Brunch
            </router-link>
            <!-- <router-link
              to="/breakfast"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Breakfast
            </router-link> -->
            <!-- <router-link
              to="/lunch"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Lunch
            </router-link> -->
            <router-link
              to="/dinner"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Dinner
            </router-link>
            <router-link
              to="/contact"
              active-class="border-sg text-sg"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Contact
            </router-link>
            <a
              href="https://www.instagram.com/onesquaredbayside/"
              target="_blank"
              class="rl border-transparent text-white hover:border-sg hover:text-sg inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Instagram
            </a>
          </PopoverGroup>
        </div>
      </div>
      <!-- -------------------------------------------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------------------------- -->
      <!-- MOBILE MENU -->
      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
        >
          <div
            class="shadow-lg ring-1 ring-black ring-opacity-5 bg-pb divide-y-2 divide-gray-50"
          >
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div>
                  <router-link to="/">
                    <img
                      class="h-8 w-auto"
                      src="@/assets/5.png"
                      alt="Workflow"
                    />
                  </router-link>
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="bg-pb p-2 inline-flex items-center justify-center text-sg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sg"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid gap-y-8">
                  <router-link
                    to="/"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </router-link>
                  <router-link
                    to="/menu"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Menu
                  </router-link>
                  <router-link
                    to="/brunch"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Brunch
                  </router-link>
                  <!-- <router-link
                    to="/breakfast"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Breakfast
                  </router-link>
                  <router-link
                    to="/lunch"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Lunch
                  </router-link> -->
                  <router-link
                    to="/dinner"
                    active-class="border-sg text-sg"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Dinner
                  </router-link>

                  <router-link
                    to="/contact"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Contact
                  </router-link>
                  <a
                    href="https://www.instagram.com/onesquaredbayside/"
                    target="_blank"
                    class="rl block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Instagram
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const services = [
  {
    name: "All Services",
    to: "/services",
  },
  {
    name: "App Development & Web Design",
    to: "/app-dev-and-web-design",
  },
  {
    name: "Graphic Design",
    to: "/graphic-design",
  },
  {
    name: "IT Services & Consultation",
    to: "/it-and-consultation",
  },
  // {
  //   name: "Mobile Game Development",
  //   description: "Coming Soon.",
  //   to: "/mobile-game-dev",
  // },
  {
    name: "Y-Network SEO",
    to: "/y-network-seo",
  },
];

const showcase = [
  {
    name: "View All",
    to: "/showcase",
  },
  {
    name: "Websites & Apps",
    to: "/websites-and-apps",
  },
  {
    name: "Clone Series Apps",
    to: "/clone-apps",
  },
  {
    name: "Graphic Design",
    to: "/graphics",
  },
  // {
  //   name: "Mobile Games",
  //   description: "Coming Soon.",
  //   to: "/mobile-games",
  // },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      services,
      showcase,
    };
  },
};
</script>
